import React from "react";
import SocialFollow from "./SocialFollow";
import "./App.css";

function App() {
  return (
    <div className="App">
      <div>
        <h1>Hi. I'm Besart Ademi</h1>
        <h2>BSc. Computer Science</h2>
        <h2>Web Developer & Network Engineer</h2>
        <SocialFollow />
      </div>
    </div>
  );
}

export default App;